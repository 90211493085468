<template>
  <div></div>
</template>
<script>
import { onMounted } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    onMounted(() => {
      root.$router.replace('/board')
    })
  }
}
</script>
